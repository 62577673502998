export class Dialog {
  constructor() {
    this.visible = false;
    this.edit = false;
    this.content = {};
  }
  open(content, edit) {
    this.visible = true;
    this.edit = edit;
    this.content = content;
  }
  close() {
    this.visible = false;
  }
  static Build() {
    return new Dialog({ visible: false, edit: false, content: {} });
  }
}

export class DialogProps {
  constructor(t) {
    this.visible = {
      type: Boolean,
      default: false,
    };
    this.edit = {
      type: Boolean,
      default: false,
    };
    this.content = {
      type: Object,
      default: () => new t(),
    };
  }
  static Build() {
    return new DialogProps(Object);
  }
}

export class NavigationMapItem {
  constructor({
    icon = "",
    text = "",
    url = "",
    permission = "",
    next = "",
    complete = false,
  }) {
    this.icon = icon;
    this.text = text;
    this.url = url;
    this.next = next;
    this.permission = permission;
    this.complete = complete;
  }
  static Build() {
    return new NavigationMapItem({
      icon: "",
      text: "",
      url: "",
      permission: "",
      next: "",
      complete: false,
    });
  }
}

export class ParametroDaComprovacao {
  constructor({
    id = null,
    ano = null,
    semestre = null,
    nome = null,
    dataInicioDaComprovacao = null,
    dataFimDaComprovacao = null,
    dataInicioDaValidacao = null,
    dataFimDaValidacao = null,
    horaInicioDaComprovacao = null,
    horaFimDaComprovacao = null,
    horaInicioDaValidacao = null,
    horaFimDaValidacao = null,
    listAnos = Array(),
    listSemestres = Array(),
  }) {
    this.id = id;
    this.ano = ano;
    this.semestre = semestre;
    this.nome = nome;
    dataInicioDaComprovacao &&
      (this.dataInicioDaComprovacao = new Date(
        dataInicioDaComprovacao
      ).toLocaleDateString());
    dataFimDaComprovacao &&
      (this.dataFimDaComprovacao = new Date(
        dataFimDaComprovacao
      ).toLocaleDateString());
    dataInicioDaValidacao &&
      (this.dataInicioDaValidacao = new Date(
        dataInicioDaValidacao
      ).toLocaleDateString());
    dataFimDaValidacao &&
      (this.dataFimDaValidacao = new Date(
        dataFimDaValidacao
      ).toLocaleDateString());
    this.horaInicioDaComprovacao = horaInicioDaComprovacao;
    this.horaFimDaComprovacao = horaFimDaComprovacao;
    this.horaInicioDaValidacao = horaInicioDaValidacao;
    this.horaFimDaValidacao = horaFimDaValidacao;
    this.listAnos = listAnos;
    this.listSemestres = listSemestres;
  }
  static Build() {
    return new ParametroDaComprovacao({
      id: null,
      ano: null,
      semestre: null,
      dataInicioDaComprovacao: null,
      dataFimDaComprovacao: null,
      dataInicioDaValidacao: null,
      dataFimDaValidacao: null,
      horaInicioDaComprovacao: null,
      horaFimDaComprovacao: null,
      horaInicioDaValidacao: null,
      horaFimDaValidacao: null,
      listAnos: Array(),
      listSemestres: Array(),
    });
  }
  format() {
    this.horaInicioDaComprovacao = new Date(
      this.dataInicioDaComprovacao
    ).toLocaleTimeString();
    this.horaFimDaComprovacao = new Date(
      this.dataFimDaComprovacao
    ).toLocaleTimeString();
    this.horaInicioDaValidacao = new Date(
      this.dataInicioDaValidacao
    ).toLocaleTimeString();
    this.horaFimDaValidacao = new Date(
      this.dataFimDaValidacao
    ).toLocaleTimeString();
    return this;
  }
}

export class Ciclo {
  constructor({
    id = null,
    ano = null,
    semestre = null,
    dataInicioSemestre = null,
    dataFimSemestre = null,
    ativo = null,
    clonarTrilha = null,
    clonarEixo = null,
    clonarFaixaPagamento = null,
    clonarValidador = null,
    clonarTipoDeAcao = null,
  }) {
    this.id = id;
    this.ano = ano;
    this.semestre = semestre;
    this.dataInicioSemestre = dataInicioSemestre;
    this.dataFimSemestre = dataFimSemestre;
    this.ativo = ativo;
    this.clonarTrilha = clonarTrilha;
    this.clonarEixo = clonarEixo;
    this.clonarFaixaPagamento = clonarFaixaPagamento;
    this.clonarValidador = clonarValidador;
    this.clonarTipoDeAcao = clonarTipoDeAcao;
  }
  static Build() {
    return new Ciclo({
      id: null,
      ano: null,
      semestre: null,
      dataInicioSemestre: null,
      dataFimSemestre: null,
      ativo: null,
      clonarTrilha: {
        tipo: 0,
        clonar: false,
        ano: null,
        semestre: null,
        habilitar: true,
      },
      clonarEixo: {
        tipo: 1,
        clonar: false,
        ano: null,
        semestre: null,
        habilitar: true,
      },
      clonarFaixaPagamento: {
        tipo: 2,
        clonar: false,
        ano: null,
        semestre: null,
        habilitar: true,
      },
      clonarValidador: {
        tipo: 3,
        clonar: false,
        ano: null,
        semestre: null,
        habilitar: true,
      },
      clonarTipoDeAcao: {
        tipo: 4,
        clonar: false,
        ano: null,
        semestre: null,
        habilitar: true,
      },
    });
  }
}

export class FAQ {
  constructor({
    ativo = null,
    id = null,
    palavrasChave = Array(),
    pergunta = null,
    posicao = null,
    resposta = null,
  }) {
    this.ativo = ativo;
    this.id = id;
    this.palavrasChave = palavrasChave;
    this.pergunta = pergunta;
    this.posicao = posicao;
    this.resposta = resposta;
  }
  static Build() {
    return new FAQ({
      ativo: null,
      id: null,
      palavrasChave: Array(),
      pergunta: null,
      posicao: null,
      resposta: null,
    });
  }
}

export class Instituicao {
  constructor({
    id = null,
    nome = null,
    nomeDesOutro = null,
    esfera = null,
    governo = null,
    poder = null,
    investimento = null,
    modalidade = null,
    url = null,
  }) {
    this.id = id;
    this.nome = nome;
    this.nomeDesOutro = nomeDesOutro;
    this.esfera = esfera;
    this.governo = governo;
    this.poder = poder;
    this.investimento = investimento;
    this.modalidade = modalidade;
    this.url = url;
  }
  static Build() {
    return new Instituicao({
      id: null,
      nome: null,
      nomeDesOutro: null,
      esfera: null,
      governo: null,
      poder: null,
      investimento: null,
      modalidade: null,
      url: null,
    });
  }
}

export class TipoAcaoCapacitacao {
  constructor({
    id = null,
    nome = null,
    cargaHorariaMinima = null,
    cargaHorariaMaxima = null,
    ano = null,
    semestre = null,
  }) {
    this.id = id;
    this.nome = nome;
    this.cargaHorariaMinima = cargaHorariaMinima;
    this.cargaHorariaMaxima = cargaHorariaMaxima;
    this.ano = ano;
    this.semestre = semestre;
  }
  static Build() {
    return new TipoAcaoCapacitacao({
      id: null,
      nome: null,
      cargaHorariaMinima: null,
      cargaHorariaMaxima: null,
      ano: null,
      semestre: null,
    });
  }
}

export class MotivoNaoValidacao {
  constructor({ motivos = Array(), validacao = null }) {
    this.motivos = motivos;
    this.validacao = validacao;
  }
  static Build() {
    return new MotivoNaoValidacao({
      motivos: Array(),
      validacao: null,
    });
  }
}

export class EixoTematico {
  constructor({
    id = null,
    nome = null,
    ano = null,
    semestre = null,
    trilhas = Array(),
  }) {
    this.id = id;
    this.nome = nome;
    this.ano = ano;
    this.semestre = semestre;
    this.trilhaItens = trilhas;
  }
  static Build() {
    return new EixoTematico({
      id: null,
      nome: null,
      ano: null,
      semestre: null,
      trilhas: [],
    });
  }
  format() {
    this.trilhaItens = this.trilhaItens.map((e) => e.id);
    return this;
  }
}

export class TrilhaDeAprendizagem {
  constructor({
    id = null,
    nome = null,
    ano = null,
    semestre = null,
    eixoItens = [],
  }) {
    this.id = id;
    this.nome = nome;
    this.ano = ano;
    this.semestre = semestre;
    this.eixoItens = eixoItens;
  }
  static Build() {
    return new TrilhaDeAprendizagem({
      id: null,
      nome: null,
      ano: null,
      semestre: null,
      eixoItens: [],
    });
  }
  format() {
    this.eixoItens = this.eixoItens.map((e) => e.id);
    return this;
  }
}

export class Validacao {
  constructor({ motivoRejeicaoId = null, motivoRejeicaoDescricao = null }) {
    this.motivoRejeicaoId = motivoRejeicaoId;
    this.motivoRejeicaoDescricao = motivoRejeicaoDescricao;
  }
  static Build() {
    return new Validacao({
      motivoRejeicaoId: null,
      motivoRejeicaoDescricao: null,
    });
  }
}

export class Anexo {
  constructor({ descricao = null, path = null }) {
    this.descricao = descricao;
    this.path = path;
  }
  static Build() {
    return new Anexo({
      descricao: null,
      path: null,
    });
  }
}

export class ValidarComprovacao {
  constructor({
    profissional = null,
    cpf = null,
    ano = null,
    semestre = null,
    cargaHoraria = null,
    mesAnoConclusao = null,
    mesAnoInicio = null,
    nomeAcaoCapacitacao = null,
    tipoDaCondicaoCapacitacao = null,
    anexo = Anexo.Build(),
    eixoTematico = EixoTematico.Build(),
    instituicao = Instituicao.Build(),
    tipoAcaoCapacitacao = TipoAcaoCapacitacao.Build(),
    trilhaDeAprendizagem = TrilhaDeAprendizagem.Build(),
    validacao = Validacao.Build(),
  }) {
    this.profissional = profissional;
    this.cpf = cpf;
    this.anexo = anexo;
    this.ano = ano;
    this.semestre = semestre;
    this.cargaHoraria = cargaHoraria;
    this.eixoTematico = eixoTematico;
    this.instituicao = instituicao;
    this.tipoAcaoCapacitacao = tipoAcaoCapacitacao;
    this.mesAnoConclusao = mesAnoConclusao;
    this.mesAnoInicio = mesAnoInicio;
    this.nomeAcaoCapacitacao = nomeAcaoCapacitacao;
    this.tipoDaCondicaoCapacitacao = tipoDaCondicaoCapacitacao;
    this.trilhaDeAprendizagem = trilhaDeAprendizagem;
    this.validacao = validacao;
  }
  static Build() {
    return new ValidarComprovacao({
      profissional: null,
      cpf: null,
      ano: null,
      semestre: null,
      cargaHoraria: null,
      mesAnoConclusao: null,
      mesAnoInicio: null,
      nomeAcaoCapacitacao: null,
      tipoDaCondicaoCapacitacao: null,
      anexo: Anexo.Build(),
      eixoTematico: EixoTematico.Build(),
      instituicao: Instituicao.Build(),
      tipoAcaoCapacitacao: TipoAcaoCapacitacao.Build(),
      trilhaDeAprendizagem: TrilhaDeAprendizagem.Build(),
      validacao: Validacao.Build(),
    });
  }
}

export class CadastrarValidacaoComprovacao {
  constructor({
    ano = null,
    semestre = null,
    valid = false,
    instituicaoId = null,
    matricula = null,
    nomeDeOutraInstituicao = null,
    tipoCapacitacaoId = null,
    eixoTematicoId = null,
    trilhaDeAprendizagemId = null,
    nomeDaAcaoDeCapacitacao = null,
    tipoDaCondicaoDeCapacitacao = null,
    cargaHoraria = null,
    dataInicioCapacitacao = null,
    dataFimCapacitacao = null,
    anexo = Anexo.Build(),
    atendeCriterioElegibilidade = null,
  }) {
    this.ano = ano;
    this.semestre = semestre;
    this.valid = valid;
    this.instituicaoId = instituicaoId;
    this.matricula = matricula;
    this.nomeDeOutraInstituicao = nomeDeOutraInstituicao;
    this.tipoCapacitacaoId = tipoCapacitacaoId;
    this.eixoTematicoId = eixoTematicoId;
    this.trilhaDeAprendizagemId = trilhaDeAprendizagemId;
    this.nomeDaAcaoDeCapacitacao = nomeDaAcaoDeCapacitacao;
    this.tipoDaCondicaoDeCapacitacao = tipoDaCondicaoDeCapacitacao;
    this.cargaHoraria = cargaHoraria;
    this.dataInicioCapacitacao = dataInicioCapacitacao;
    this.dataFimCapacitacao = dataFimCapacitacao;
    this.anexo = anexo;
    this.atendeCriterioElegibilidade = atendeCriterioElegibilidade;
  }
  static Build() {
    return new CadastrarValidacaoComprovacao({
      ano: null,
      semestre: null,
      valid: false,
      instituicaoId: null,
      matricula: null,
      nomeDeOutraInstituicao: null,
      tipoCapacitacaoId: null,
      eixoTematicoId: null,
      trilhaDeAprendizagemId: null,
      nomeDaAcaoDeCapacitacao: null,
      tipoDaCondicaoDeCapacitacao: null,
      cargaHoraria: null,
      dataInicioCapacitacao: null,
      dataFimCapacitacao: null,
      anexo: Anexo.Build(),
      atendeCriterioElegibilidade: null,
    });
  }
}

export class HistoricoComprovacao {
  constructor() {
    this.comprovacoes = Array();
  }
  static Build() {
    return new HistoricoComprovacao({
      comprovacoes: Array(),
    });
  }
}

export class Grupo {
  constructor({
    id = null,
    nome = null,
    nomeDeExibicao = null,
    posicao = null,
    permissoes = Array(),
  }) {
    this.id = id;
    this.nome = nome;
    this.nomeDeExibicao = nomeDeExibicao;
    this.posicao = posicao;
    this.permissoes = permissoes;
  }
  static Build() {
    return new Grupo({
      id: null,
      nome: null,
      nomeDeExibicao: null,
      posicao: null,
      permissoes: Array(),
    });
  }
  format() {
    this.permissoes = this.permissoes.map((p) => p.id);
    return this;
  }
}

export class Perfil {
  constructor({
    id = null,
    nome = null,
    ativo = null,
    adm = null,
    grupos = Array(),
  }) {
    this.id = id;
    this.nome = nome;
    this.ativo = ativo;
    this.adm = adm;
    this.grupos = grupos;
  }
  static Build() {
    return new Perfil({
      id: null,
      nome: null,
      ativo: null,
      adm: null,
      grupos: Array(),
    });
  }
  format() {
    this.grupos = this.grupos.map((p) => p.id);
    return this;
  }
}
export class Usuario {
  constructor({
    id = null,
    ativo = null,
    nome = null,
    email = null,
    cpf = null,
    ano = null,
    semestre = null,
    grupos = Array(),
    perfis = Array(),
    matriculas = Array(),
    validadores = Array(),
    usuarioValidador = null,
    usuarioSadRh = null,
    lotacoes = Array(),
    validadorAtivo = null,
    validarTodasLotacoes = null,
    visualizarTodasLotacoes = null,
  }) {
    this.id = id;
    this.ativo = ativo;
    this.nome = nome;
    this.email = email;
    this.cpf = cpf;
    this.ano = ano;
    this.semestre = semestre;
    this.grupos = grupos;
    this.perfis = perfis;
    this.matriculas = matriculas;
    this.validadores = validadores;
    this.usuarioValidador = usuarioValidador;
    this.usuarioSadRh = usuarioSadRh;
    this.lotacoes = lotacoes;
    this.validadorAtivo = validadorAtivo;
    this.validarTodasLotacoes = validarTodasLotacoes;
    this.visualizarTodasLotacoes = visualizarTodasLotacoes;
  }
  static Build() {
    return new Usuario({
      id: null,
      ativo: true,
      nome: null,
      email: null,
      cpf: null,
      ano: null,
      semestre: null,
      grupos: Array(),
      perfis: Array(),
      matriculas: Array(),
      validadores: Array(),
      usuarioValidador: null,
      usuarioSadRh: null,
      lotacoes: Array(),
      validadorAtivo: null,
      validarTodasLotacoes: null,
      visualizarTodasLotacoes: null,
    });
  }
  format() {
    this.validadores = this.validadores
      .map((v) => ({
        ...v,
        lotacoes: v.lotacoes.filter((l) => l.ativa).map((l) => l.codigo),
      }))
      .find((v) => v);
    this.matriculas = this.matriculas.find((v) => v);
    this.grupos = this.grupos.map((p) => p.id);
    this.perfis = this.perfis.map((p) => p.id);
    this.validadorAtivo = this.validadores?.validadorAtivo;
    this.validarTodasLotacoes = this.validadores?.validarTodasLotacoes;
    this.visualizarTodasLotacoes = this.validadores?.visualizarTodasLotacoes;
    this.ano = this.validadores?.ano;
    this.semestre = this.validadores?.semestre;
    this.lotacoes = this.validadores?.lotacoes;
    return this;
  }
}

export class TrocarSenha {
  constructor({
    token = null,
    nomeDeUsuario = null,
    primeiroAcesso = null,
    visible = null,
  }) {
    this.token = token;
    this.nomeDeUsuario = nomeDeUsuario;
    this.primeiroAcesso = primeiroAcesso;
    this.visible = visible;
  }
  static Build() {
    return new TrocarSenha({
      token: null,
      nomeDeUsuario: null,
      primeiroAcesso: null,
      visible: null,
    });
  }
}

export class Motivo {
  constructor({ id = null, descricao = null }) {
    this.id = id;
    this.descricao = descricao;
  }
  static Build() {
    return new Motivo({
      id: null,
      descricao: null,
    });
  }
}

export class FaixaDePagamento {
  constructor({
    id = null,
    ano = null,
    semestre = null,
    porcentagemDePagamento = null,
    condicaoDeCapacitacao = null,
    cargaHorariaMinima = null,
    cargaHorariaMaxima = null,
    ativo = false,
  }) {
    this.id = id;
    this.ano = ano;
    this.semestre = semestre;
    this.porcentagemDePagamento = porcentagemDePagamento;
    this.condicaoDeCapacitacao = condicaoDeCapacitacao;
    this.cargaHorariaMinima = cargaHorariaMinima;
    this.cargaHorariaMaxima = cargaHorariaMaxima;
    this.ativo = ativo;
  }
  static Build() {
    return new FaixaDePagamento({
      id: null,
      ano: null,
      semestre: null,
      porcentagemDePagamento: null,
      condicaoDeCapacitacao: null,
      cargaHorariaMinima: null,
      cargaHorariaMaxima: null,
      ativo: false,
    });
  }
}

export class VerbaPagamento {
  constructor({
    id = null,
    dataInicioVerba = null,
    horaInicioVerba = null,
    dataFimVerba = null,
    horaFimVerba = null,
    verba = null,
  }) {
    this.id = id;
    this.dataInicioVerba = dataInicioVerba;
    this.horaInicioVerba = horaInicioVerba;
    this.dataFimVerba = dataFimVerba;
    this.horaFimVerba = horaFimVerba;
    this.verba = verba;
  }
  static Build() {
    return new VerbaPagamento({
      id: null,
      dataInicioVerba: null,
      horaInicioVerba: null,
      dataFimVerba: null,
      horaFimVerba: null,
      verba: null,
    });
  }
  format() {
    const format = { hour: "2-digit", minute: "2-digit" };
    this.horaInicioVerba = new Date(this.dataInicioVerba).toLocaleTimeString(
      [],
      format
    );
    this.horaFimVerba = new Date(this.dataFimVerba).toLocaleTimeString(
      [],
      format
    );
    this.dataInicioVerba = new Date(this.dataInicioVerba).toLocaleDateString();
    this.dataFimVerba = new Date(this.dataFimVerba).toLocaleDateString();
    return this;
  }
}

export class Termo {
  constructor({
    id = null,
    nome = null,
    tipoDoTermo = null,
    dataInicio = null,
    dataFim = null,
    ativo = null,
    html = null,
  }) {
    this.id = id;
    this.nome = nome;
    this.tipoDoTermo = tipoDoTermo;
    this.dataInicio = dataInicio;
    this.dataFim = dataFim;
    this.ativo = ativo;
    this.html = html;
  }
  static Build() {
    return new Termo({
      id: null,
      nome: null,
      tipoDoTermo: null,
      dataInicio: null,
      dataFim: null,
      ativo: null,
      html: null,
    });
  }
  format() {
    this.dataInicio = new Date(this.dataInicio).toLocaleDateString();
    this.dataFim = new Date(this.dataFim).toLocaleDateString();
    return this;
  }
}
